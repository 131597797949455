<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="padding: 10px;"
                cols="12"
                lg="3"
                md="6"
                sm="12"
            >
                <h3>Pipeline - Service</h3>
            </v-col>
            <v-col 
                style="padding-top: 10px; display: flex;"
                cols="12"
                lg="3"
                md="12"
                sm="12"
            >
                <v-combobox
                    v-model="sortSelected"
                    :items="listSortOptions"
                    label="Sort By"
                    item-text="description"
                    item-value="id"
                    clearable
                    outlined
                    @change="sortRegisters"
                    dense
                ></v-combobox>

                <v-tooltip bottom v-if="sortType == 0">
                    <template v-slot:activator="{ on }" v-if="sortType == 0">
                        <v-btn class="btnToogle" v-on="on" v-if="sortType == 0" @click="sortType = 1; sortRegisters()">
                            <v-icon>mdi-sort-ascending</v-icon>
                        </v-btn>
                    </template>
                    <span>Order by ascending</span>
                </v-tooltip>

                <v-tooltip bottom v-if="sortType == 1">
                    <template v-slot:activator="{ on }" v-if="sortType == 1">
                        <v-btn class="btnToogle" v-on="on" v-if="sortType == 1" @click="sortType = 0; sortRegisters()">
                            <v-icon>mdi-sort-descending</v-icon>
                        </v-btn>
                    </template>
                    <span>Order by descending</span>
                </v-tooltip>
            </v-col>
            <v-col 
                style="padding: 10px;"
                cols="12"
                lg="2"
                md="12"
                sm="12"
            >
                <v-combobox
                    :items="listUser"
                    label="Search by Supervisor"
                    item-text="name"
                    item-value="id"
                    multiple
                    clearable
                    single-line
                    outlined
                    dense
                ></v-combobox>
            </v-col>
            <v-col 
                style="padding: 10px;"
                cols="12"
                lg="2"
                md="12"
                sm="12"
            >
                <v-autocomplete
                    v-model="projectSelected"
                    :items="listProjects"
                    :loading="isLoading"
                    :search-input.sync="searchProject"
                    hide-selected
                    :no-data-text="textNoData"
                    item-text="description"
                    item-value="id"
                    placeholder="Card Search"
                    prepend-inner-icon="mdi mdi-magnify"
                    outlined
                    return-object
                    dense
                ></v-autocomplete>
            </v-col>
            <v-col 
                style="padding: 10px;"
                cols="12"
                lg="2"
                md="12"
                sm="12"
            >
                <v-form @submit.prevent="getRegisters">
                    <v-text-field
                        v-model="filter.fastSearch"
                        label="Filter Current View"
                        prepend-inner-icon="mdi mdi-filter"
                        outlined
                        single-line
                        @keyup="filteredSearch"
                        @click:prepend-inner="getRegisters"
                        dense
                    ></v-text-field>
                </v-form>
            </v-col>
        </v-row>

        <div id="divHeaders" style="width: 100%; overflow-x: auto" class="sticky" @scroll="syncScroll">

            <v-row 
                class="rowWrap"
            >
                <v-col 
                    v-for="(itemCard, index) in listCards" :key="index"
                    class="colWrap"
                    cols="12"
                    lg="2"
                    md="3"
                    sm="12"
                >
                    <v-card>
                        <v-card-title :class="`text-h6 lighten-2 pipelineTitle ${itemCard.cssClass}`">
                            <v-col 
                                cols="10"
                            >
                                <v-icon left class="pipelineWhiteColor">mdi mdi-card-text-outline</v-icon>
                                <label class="pipelineWhiteColor">{{ itemCard.title }}</label>
                            </v-col>
                            <v-col 
                                cols="2"
                                style="text-align: right;"
                            >
                                <label class="pipelineWhiteColor"> {{ itemCard.qtd }} </label>
                            </v-col>
                        </v-card-title>
                    </v-card>
                </v-col>

            </v-row>
        </div>

        <div id="divContents" style="width: 100%; overflow-x: auto" @scroll="syncScroll">
            <v-row 
                class="rowWrap"
            >
                <v-col 
                    v-for="(itemCard, index) in listCards" :key="index"
                    class="colWrap"
                    cols="12"
                    lg="2"
                    md="3"
                    sm="12"
                >   
                    <v-card 
                        class="pipelineDetails"
                        v-for="(itemProject, index) in itemCard.listItems" :key="index"
                    >
                        <v-progress-linear 
                            :value="(100/4) * parseInt(itemProject.step)" 
                            style="margin-top: 20px"
                        >
                        </v-progress-linear>

                        <ConstructionCard
                            :id="itemProject.id"
                            :itemProject="{...itemProject}"
                            :itemCard="{...itemCard}"
                            v-on:methodConfirmToCall="getRegisters()"
                        />
                    </v-card>
                    
                </v-col>

            </v-row>
        </div>
        
    </v-container>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
    import { filterValuePart, orderBy } from '@/utilities/Utils';
    import ConstructionCard from "@/components/Services/ConstructionCard";
    import { 
        PROJECT_STATUS,
        CONSTRUCTION_PANEL,
        CONSTRUCTION_STAGE 
    } from "@/utilities/Enums";

    export default ({

        mixins: [Helpers],

        components: {
            ConstructionCard
        },

        data: () => ({

            filter: {
                fastSearch: ''
            },

            idPanel: CONSTRUCTION_PANEL.SERVICE,
            listStages: CONSTRUCTION_STAGE,
            listUser: [],

            listCards:[],

            listIncomingProjects: [],
            listIncomingProjectsFiltered: [],
            qtdIncomingProjects: 0,

            listFireUp: [],
            listFireUpFiltered: [],
            qtdFireUp: 0,

            listAfterPlaster: [],
            listAfterPlasterFiltered: [],
            qtdAfterPlaster: 0,

            listdTurnOver: [],
            listdTurnOverFiltered: [],
            qtddTurnOver: 0,

            listInspection: [],
            listInspectionFiltered: [],
            qtdInspection: 0,

            sortSelected: null,
            sortType: 0,
            listSortOptions: [
                { id: 1, description: 'Registration Date', listField: ['id'] },
                { id: 2, description: 'Deadline', listField: ['dateScopeWorkStep1DeadlineDays', 'dateScopeWorkStep2DeadlineDays', 'dateScopeWorkStep3DeadlineDays', 'dateScopeWorkStep4DeadlineDays'] },
                { id: 3, description: 'Responsible', listField: ['permitterUserName'] },
                { id: 4, description: 'Supervisor', listField: ['id'] },
                { id: 5, description: 'Contract Amount', listField: ['contractValue'] }
            ],
            textNoData: 'Type to search a project...',
            projectSelected: null,
            searchProject: null,
            listProjects: [],
            isLoading: false,

            PROJECT_STATUS
        }),

        watch: {
            async searchProject(search) {
                
                if (search == null || search == undefined || search.toString().trim() == '') {
                    this.listProjects = [];
                    this.textNoData = "Type to search a project...";
                }
                else {

                    if (this.listProjects.length <= 0) {
                        this.textNoData = "No projects found";
                    }

                    this.isLoading = true;

                    this.listProjects = await this.$store.dispatch("serviceModule/ListProjectCompact", search);

                    this.isLoading = false;
                }
            }
        },

        methods: {

            filteredSearch() {

                let filter = this.filter.fastSearch.toLowerCase();                
                
                this.listCards.forEach(itemCard => {

                    itemCard.listItems = filterValuePart(itemCard.listItemsClone, filter, ['step', 'customerAddress', 'dateScopeWorkStep4Formatted', 'sellerName', 'customerName', 'nameProject', 'idFormatted']);

                })

                return null;
            },

            sortRegisters() {

                let field = ['id'];

                if (this.sortSelected != null && this.sortSelected != undefined) {
                    field = this.sortSelected.listField;
                }
                
                this.listCards.forEach(itemCard => {

                    itemCard.listItems.sort((a, b) => { return orderBy(a, b, this.sortType, field[0]) });

                })
            },

            async checkNotes(idServiceProject) {
                
                this.listNote = await this.$store.dispatch("serviceModule/ListProjectNote", { 
                    idService: 0, 
                    idServiceProject, 
                    onlyCheckExists: true, 
                    idStatus: 0, 
                    idPanel: 0,
                    idStage: 0, 
                    idUser: 0 
                } );

                if (this.listNote.length > 0) return 1; else return 0;
            },

            async checkProductsIcons(idServiceProject) {
                return await this.$store.dispatch("productModule/ListProductsWithIconInProject", { idService: 0, idServiceProject });
            },

            async checkIcons() {

                this.listCards.forEach(itemCard => {

                    itemCard.listItems.forEach(async itemProject => {
                        itemProject.showIconNotes = await this.checkNotes(itemProject.id);
                        itemProject.listProductsWithIcons = await this.checkProductsIcons(itemProject.id);

                        this.$forceUpdate();
                    });

                })
            },

            async getRegisters() {

                let listUserResponse = await this.$store.dispatch("userModule/List");
                this.sortSelected = this.listSortOptions[0];

                this.listUser = listUserResponse.filter(usr => usr.constructionSupervisor == 1);

                let response = await this.$store.dispatch("serviceModule/ListPipelineService");

                if (response != null && response != undefined) {

                    this.listCards = [];

                    if (response.listPipeline && response.listPipeline != null && response.listPipeline != undefined) {

                        this.listIncomingProjects = [...response.listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_PLASTE_OPENITEMS.id || pip.statusOriginal == this.PROJECT_STATUS.CONSTRUCTION_PLASTE_OPENITEMS.id)];
                        if (this.listIncomingProjects != null && this.listIncomingProjects != undefined) {
                            this.qtdIncomingProjects = this.listIncomingProjects.length;
                        }
                        this.listIncomingProjectsFiltered = [...this.listIncomingProjects];

                        this.listFireUp = [...response.listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_SERVICE_FIREUP.id || pip.statusOriginal == this.PROJECT_STATUS.CONSTRUCTION_SERVICE_FIREUP.id)];
                        if (this.listFireUp != null && this.listFireUp != undefined) {
                            this.qtdFireUp = this.listFireUp.length;
                        }
                        this.listFireUpFiltered = [...this.listFireUp];


                        this.listAfterPlaster = [...response.listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_SERVICE_AFTERPLASTER.id || pip.statusOriginal == this.PROJECT_STATUS.CONSTRUCTION_SERVICE_AFTERPLASTER.id)];
                        if (this.listAfterPlaster != null && this.listAfterPlaster != undefined) {
                            this.qtdAfterPlaster = this.listAfterPlaster.length;
                        }
                        this.listAfterPlasterFiltered = [...this.listAfterPlaster];


                        this.listdTurnOver = [...response.listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_SERVICE_TURNOVER.id || pip.statusOriginal == this.PROJECT_STATUS.CONSTRUCTION_SERVICE_TURNOVER.id)];
                        if (this.listdTurnOver != null && this.listdTurnOver != undefined) {
                            this.qtddTurnOver = this.listdTurnOver.length;
                        }
                        this.listdTurnOverFiltered = [...this.listdTurnOver];


                        this.listInspection = [...response.listPipeline.filter(pip => pip.status == this.PROJECT_STATUS.CONSTRUCTION_SERVICE_INSPECTION.id || pip.statusOriginal == this.PROJECT_STATUS.CONSTRUCTION_SERVICE_INSPECTION.id)];
                        if (this.listInspection != null && this.listInspection != undefined) {
                            this.qtdInspection = this.listInspection.length;
                        }
                        this.listInspectionFiltered = [...this.listInspection];

                        
                        this.listCards.push({
                            title: 'Incoming Projects',
                            idStatusCard: this.PROJECT_STATUS.CONSTRUCTION_PLASTE_OPENITEMS.id,
                            qtd: this.listIncomingProjects.length,
                            listItems: this.listIncomingProjects,
                            cssClass: 'incomingProjects',
                            showSelectSupervisor: true,
                            idPanel: this.idPanel, 
                            idStage: this.listStages.NO_STAGE,
                            moreOptions: {
                                jobSpecifics: true,
                                hold: false,
                                workOrder: false,
                                inspection: false,
                                showAdvanceButton: false,
                                showBackButton: false,
                            },
                        })
                        
                        this.listCards.push({
                            title: 'Fire Up',
                            idStatusCard: this.PROJECT_STATUS.CONSTRUCTION_SERVICE_FIREUP.id,
                            qtd: this.listFireUp.length,
                            listItems: this.listFireUp,
                            cssClass: 'colorPipeline',
                            showSelectSupervisor: false,
                            idPanel: this.idPanel, 
                            idStage: this.listStages.SERVICE_FIREUP,
                            moreOptions: {
                                jobSpecifics: true,
                                hold: true,
                                workOrder: true,
                                inspection: true,
                                showAdvanceButton: true,
                                showBackButton: false
                            },
                        })
                        
                        this.listCards.push({
                            title: 'After Plaster',
                            idStatusCard: this.PROJECT_STATUS.CONSTRUCTION_SERVICE_AFTERPLASTER.id,
                            qtd: this.listAfterPlaster.length,
                            listItems: this.listAfterPlaster,
                            cssClass: 'colorPipeline',
                            showSelectSupervisor: false,
                            idPanel: this.idPanel, 
                            idStage: this.listStages.SERVICE_AFTERPLASTER,
                            moreOptions: {
                                jobSpecifics: true,
                                hold: true,
                                workOrder: true,
                                inspection: true,
                                showAdvanceButton: true,
                                showBackButton: true
                            },
                        })
                        
                        this.listCards.push({
                            title: 'Turn Over',
                            idStatusCard: this.PROJECT_STATUS.CONSTRUCTION_SERVICE_TURNOVER.id,
                            qtd: this.listdTurnOver.length,
                            listItems: this.listdTurnOver,
                            cssClass: 'colorPipeline',
                            showSelectSupervisor: false,
                            idPanel: this.idPanel, 
                            idStage: this.listStages.SERVICE_TURNOVER,
                            moreOptions: {
                                jobSpecifics: true,
                                hold: true,
                                workOrder: true,
                                inspection: true,
                                showAdvanceButton: true,
                                showBackButton: true
                            },
                        })
                        
                        this.listCards.push({
                            title: 'Inspection',
                            idStatusCard: this.PROJECT_STATUS.CONSTRUCTION_SERVICE_INSPECTION.id,
                            qtd: this.listInspection.length,
                            listItems: this.listInspection,
                            cssClass: 'colorPipeline',
                            showSelectSupervisor: false,
                            idPanel: this.idPanel, 
                            idStage: this.listStages.SERVICE_INSPECTION,
                            moreOptions: {
                                jobSpecifics: true,
                                hold: true,
                                workOrder: true,
                                inspection: true,
                                showAdvanceButton: false,
                                showBackButton: true
                            },
                        })
                
                        this.listCards.forEach(itemCard => {

                            itemCard.listItemsClone = [...itemCard.listItems];

                        })                        

                        await this.checkIcons();
                    }
                }
            },
        },

        async created() {
            this.showLoading();
            await this.getRegisters();
            this.hideLoading();
        },
    })
</script>

<style scoped>

    .colorPipeline {
        background-color: var(--color__construction_dashboard_predig);
    }

    .v-progress-linear__determinate {
        background-color: var(--color__construction_dashboard_predig) !important; 
    }
</style>